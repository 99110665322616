export { base64ToFile } from "./base64ToFile";
export { convertStringIntoSVGBase64 } from "./convertStringIntoSVGBase64";
export { getAddressFields } from "./getAddressFields";
export { getImageDimensions } from "./getImageDimensions";
export { getManualIdFields } from "./getManualIdFields";
export { findSubmitButtonText } from "./findSubmitButtonText";
export { getNextStep } from "./getNextStep";
export { getSexData } from "./getSexData";
export { getVidSteps } from "./getVidSteps";
export { handleOrderCreationError } from "./handleOrderCreationError";
export { reformatAdditionalInformation } from "./reformatAdditionalInformation";
export { sanitizeAddresses } from "./sanitizeAddresses";
export { isMobileBrowser } from "./isMobileBrowser";
